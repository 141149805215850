import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectUserIsHSEEmployee } from '../../../auth/state/authSlice';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import ErrorPageDialog, {
  ErrorPageDialogType,
} from '../../../notifications/components/ErrorPageDialog/ErrorPageDialog';
import {
  clearProductsSelection,
  selectProductSelection,
} from '../../../products/state/productsSlice';
import Loading from '../../../shows/component/Loading/Loading';
import theme from '../../../theme/theme.module.scss';
import { changeWhiteSpacesToHTMLFormat } from '../../../utils/formatters/htmlFormatter/htmlFormatter';
import AdminCreateEditPostForm from '../../components/AdminCreateEditPostForm/AdminCreateEditPostForm';
import CreatePostHeader from '../../components/CreatePostHeader/CreatePostHeader';
import { AdminStreamPostData } from '../../model/post';
import {
  clearPostCreationData,
  fetchStreamerPost,
  selectPostIsBeingLoaded,
  selectStreamerPost,
  setIsInitialUpload,
  updatePostByAdmin,
} from '../../state/postsSlice';

const UpdatePostView = () => {
  const dispatch = useDispatch();
  const { postId } = useParams<{ postId: string }>();
  const post = useSelector(selectStreamerPost);
  const loading = useSelector(selectPostIsBeingLoaded);
  const { selectedProducts } = useSelector(selectProductSelection);
  const isAdmin: boolean = useSelector(selectUserIsHSEEmployee);

  const onUpdatePost = ({ medias, caption, scheduleAt, salesTheme }: AdminStreamPostData) => {
    if (medias.length > 0 && caption?.trim()) {
      dispatch(
        updatePostByAdmin({
          postId,
          request: {
            caption: changeWhiteSpacesToHTMLFormat(caption),
            baseProductsNo: selectedProducts.map(p => p.baseProductNo),
            scheduleAt: scheduleAt,
            medias,
            salesTheme,
          },
        })
      );
    }
  };

  useEffect(() => {
    dispatch(clearProductsSelection());
    dispatch(clearPostCreationData());
    dispatch(setIsInitialUpload(false));
    dispatch(fetchStreamerPost(postId));
  }, [postId]);

  if (loading) {
    return <Loading />;
  }

  if (!post) {
    return (
      <ErrorPageDialog errorDialogType={ErrorPageDialogType.ENTITY_NOT_FOUND} entityLabel="Post" />
    );
  }

  return (
    <>
      <CreatePostHeader title="Post ändern" />
      <AdminCreateEditPostForm
        postToUpdate={post}
        isOwnPost={isAdmin}
        submitCallback={onUpdatePost}
      />
    </>
  );
};

function AdminUpdatePostPage() {
  return (
    <PageContainer
      backgroundColor={theme.lightgreyLightest}
      pageToRender={<UpdatePostView />}
      fullHeight
    />
  );
}

export default AdminUpdatePostPage;
