import { FunctionComponent, SVGProps } from 'react';
import { ReactComponent as ArrowDown } from './arrow-down.svg';
import { ReactComponent as ArrowUpFromBracket } from './arrow-up-from-bracket.svg';
import { ReactComponent as ArrowUpV24 } from './arrow-up.svg';
import { ReactComponent as ArrowUp } from './arrow_up.svg';
import { ReactComponent as CropV24 } from './aspect-ratio-crop.svg';
import { ReactComponent as CalendarIcon } from './calendar.svg';
import { ReactComponent as CheckIcon } from './check-icon.svg';
import { ReactComponent as CloseIcon } from './close-icon.svg';
import { ReactComponent as CloseInCircleIcon } from './close-in-circle.svg';
import { ReactComponent as ClothesHanger } from './clothes-hanger.svg';
import { ReactComponent as CommentsIcon } from './comments.svg';
import { ReactComponent as CopyIcon } from './copy-icon.svg';
import { ReactComponent as Crop } from './crop.svg';
import { ReactComponent as EditIcon } from './edit-icon.svg';
import { ReactComponent as Error } from './error.svg';
import { ReactComponent as ExternalLinkIcon } from './external-link.svg';
import { ReactComponent as EyesViewOffInvisibleIcon } from './eyes-view-off-invisible.svg';
import { ReactComponent as EyesViewOnVisibleIcon } from './eyes-view-on-visible.svg';
import { ReactComponent as File } from './file.svg';
import { ReactComponent as FileSize } from './fileSize.svg';
import { ReactComponent as HomeIcon } from './home-icon.svg';
import { ReactComponent as LiveIcon } from './icon-play.svg';
import { ReactComponent as ImageBackgroundIcon } from './image-background.svg';
import { ReactComponent as ImageIcon } from './image.svg';
import { ReactComponent as LogoutIcon } from './logout.svg';
import { ReactComponent as MarketplaceIcon } from './marketplace-icon.svg';
import { ReactComponent as MoreIcon } from './more-horizontal-icon.svg';
import { ReactComponent as OpticalAlignmentFrameIcon } from './optical-alignment-frame.svg';
import { ReactComponent as PencilIcon } from './pencil-icon.svg';
import { ReactComponent as PersonIcon } from './personIcon.svg';
import { ReactComponent as Photo } from './photo.svg';
import { ReactComponent as Pictures } from './pictures.svg';
import { ReactComponent as Play } from './play.svg';
import { ReactComponent as PlusIcon20 } from './plus-icon-20.svg';
import { ReactComponent as PlusIcon } from './plus-icon.svg';
import { ReactComponent as SandGlassIcon } from './sand-glass.svg';
import { ReactComponent as SearchIcon } from './search-icon.svg';
import { ReactComponent as SettingsIcon } from './settings-icon.svg';
import { ReactComponent as ShieldIcon } from './shield.svg';
import { ReactComponent as BasketIcon } from './shoppingbag.svg';
import { ReactComponent as StarWithCircle } from './star-with-circle.svg';
import { ReactComponent as Success } from './success.svg';
import { ReactComponent as TrashBin } from './trash-bin.svg';
import { ReactComponent as UploadIcon } from './upload-icon.svg';
import { ReactComponent as UserIcon } from './user-icon.svg';
import { ReactComponent as UserProfileIcon } from './user-profile.svg';
import { ReactComponent as UserSmallIcon } from './user_small.svg';
import { ReactComponent as VerifiedUser } from './verified-user.svg';
import { ReactComponent as VideoCameraWithCircle } from './video-camera-with-circle.svg';
import { ReactComponent as VideoCamera } from './video-camera.svg';
import { ReactComponent as VideosIcon } from './videos.svg';
import { ReactComponent as VoucherIcon } from './voucher-icon.svg';

export type SvgIcon = FunctionComponent<SVGProps<SVGSVGElement>>;

const Icons: Record<string, SvgIcon> = {
  arrowUpFromBracket: ArrowUpFromBracket,
  basketIcon: BasketIcon,
  calendarIcon: CalendarIcon,
  checkIcon: CheckIcon,
  closeIcon: CloseIcon,
  closeInCircleIcon: CloseInCircleIcon,
  clothesHanger: ClothesHanger,
  copyIcon: CopyIcon,
  crop: Crop,
  editIcon: EditIcon,
  externalLinkIcon: ExternalLinkIcon,
  eyesViewOffInvisibleIcon: EyesViewOffInvisibleIcon,
  eyesViewOnVisibleIcon: EyesViewOnVisibleIcon,
  file: File,
  fileSize: FileSize,
  homeIcon: HomeIcon,
  imageBackgroundIcon: ImageBackgroundIcon,
  liveIcon: LiveIcon,
  logoutIcon: LogoutIcon,
  marketplaceIcon: MarketplaceIcon,
  moreIcon: MoreIcon,
  pencilIcon: PencilIcon,
  personIcon: PersonIcon,
  photo: Photo,
  play: Play,
  plusIcon20: PlusIcon20,
  plusIcon: PlusIcon,
  sandGlassIcon: SandGlassIcon,
  searchIcon: SearchIcon,
  settingsIcon: SettingsIcon,
  shieldIcon: ShieldIcon,
  starWithCircle: StarWithCircle,
  uploadIcon: UploadIcon,
  userIcon: UserIcon,
  verifiedUser: VerifiedUser,
  videoCamera: VideoCamera,
  videoCameraWithCircle: VideoCameraWithCircle,
  voucherIcon: VoucherIcon,
  success: Success,
  error: Error,
  pictures: Pictures,
  arrowUp: ArrowUp,
  arrowUpv24: ArrowUpV24,
  arrowDown: ArrowDown,
  cropV24: CropV24,
  trashBin: TrashBin,
  commentsIcon: CommentsIcon,
  imageIcon: ImageIcon,
  opticalAlignmentFrameIcon: OpticalAlignmentFrameIcon,
  userSmallIcon: UserSmallIcon,
  videosIcon: VideosIcon,
  userProfile: UserProfileIcon,
};

export default Icons;
