import { Divider, Grid } from '@mui/material';
import { lazy } from 'react';
import { useSelector } from 'react-redux';
import TextAreaInput from '../../../../components/TextArea/TextAreaInput';
import TextField from '../../../../components/TextField/TextField';
import { selectLoggedInCreator } from '../../../state/creatorsSlice';
import { profilePersonalDataKeys, profilePersonalDataLabels } from '../ProfilePersonalData';
import styles from './ProfileDataForm.module.scss';

const ProfileImageSelection = lazy(() => import('../ProfileImageSelection/ProfileImageSelection'));

const ProfileDataForm = () => {
  const profileData = useSelector(selectLoggedInCreator);

  return (
    <Grid container direction={'column'} spacing={2}>
      <div className={styles.profile_image_selection}>
        <ProfileImageSelection />
      </div>
      <Grid item xs={12}>
        <TextField
          value={profileData?.name}
          fieldName={profilePersonalDataKeys.name}
          label={profilePersonalDataLabels.name}
          maxChars={50}
        />
      </Grid>
      <Grid item xs={12}>
        <TextAreaInput
          value={profileData?.description || ''}
          fieldName={profilePersonalDataKeys.description}
          label={`${profilePersonalDataLabels.description}*`}
          maxChars={70}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider className={styles.divider} />
      </Grid>
    </Grid>
  );
};

export default ProfileDataForm;
