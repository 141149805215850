import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { RootState } from '../../app/store';
import { Audience, PageableResponse, QueryParams } from '../../common/types';
import { DateRangeWithSelectedFilter } from '../../components/DateFilter/dateFilterTypes';
import { Pageable } from '../../creators/components/DataGridTable/ServerSideDataGrid';
import { TableFilter } from '../../creators/state/creatorsSlice';
import {
  AdminPostUpdatePayload,
  MediaType,
  PostPreSignedUrlRequest,
  PostPreSignedUrlResponse as S3File,
  PostStatusRequestResponse,
  PostUpdatePayload,
  PostWithStats,
  StreamerPostResponse,
  StreamerPostsResponse,
  UploadPostMediaToS3Request,
} from '../api/postsRequestResponse';
import { AdminPostData, Post, PostData, PostUploadStatus } from '../model/post';

export const createNewPost = createAction<PostData>('posts/createNewPost');
export const createNewPostByAdmin = createAction<AdminPostData>('posts/createNewPostByAdmin');
export const fetchStreamerPosts = createAction('posts/fetchStreamerPosts');
export const fetchStreamerPostsStats = createAction('posts/fetchStreamerPostsStats');
export const changePostStatus = createAction<PostStatusRequestResponse>('posts/changePostStatus');
export const fetchAdminPostsOverview = createAction<QueryParams>('posts/fetchAdminPostsOverview');
export const fetchStreamerPost = createAction<string>('posts/fetchStreamerPost');
export const updatePost = createAction<PostUpdatePayload>('posts/updatePost');
export const updatePostByAdmin = createAction<AdminPostUpdatePayload>('posts/updatePostByAdmin');

export const createPreSignedUrls = createAction<{
  files: {
    type: string;
    url: string | null;
    name: string;
    size: number;
    isSupported: boolean;
    index: number;
  }[];
  attachments: PostPreSignedUrlRequest[];
  isUploading: boolean;
}>('posts/createPreSingedUrls');

export const uploadPostMediaToS3 = createAction<UploadPostMediaToS3Request>(
  'posts/uploadPostMediaToS3'
);

export const cancelPostMediaUploadToS3 = createAction('posts/cancelPostMediaUploadToS3');
export const cancelFileUploadToS3 = createAction<S3File>('posts/cancelFileUploadToS3');

export const moveSelectedFileUp = createAction<FileMetaData>('posts/moveSelectedFileUp');
export const moveSelectedFileDown = createAction<FileMetaData>('posts/moveSelectedFileDown');
export const imageCropped =
  createAction<{ croppedFile: FileMetaData; newCroppedImageUrl: string; preSignedUrl: string }>(
    'posts/imageCropped'
  );
export const imageCroppedInUpdate = createAction<{
  croppedFile: FileMetaData;
  newCroppedImageUrl: string;
  s3File: S3File;
}>('posts/imageCroppedInUpdate');

export enum FileExtension {
  MOV = 'mov',
  MP4 = 'mp4',
  MKV = 'mkv',
  WEBM = 'webm',
  MPEG = 'mpeg',
  QUICKTIME = 'quicktime',
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
}

export enum Resolution {
  MP4_1080P = 'MP4_1080P',
  MP4_720P = 'MP4_720P',
  MP4_480P = 'MP4_480P',
}

export interface PostEntry {
  resolution: Resolution;
  url: string;
}

export interface VideoEntry {
  resolutions: PostEntry[];
}

export interface VideoMetaData {
  duration?: number;
}

export interface VideoPayload {
  thumbnailUrl?: string;
  videoEntries?: VideoEntry[];
  metadata?: VideoMetaData;
  preview?: VideoEntry;
}

export interface Media {
  mediaType: MediaType;
  url: string;
  extension: FileExtension;
}

export interface MediaRequest extends Media {
  thumbnailUrl?: string;
  videoEntries?: VideoEntry[];
  metadata?: VideoMetaData;
  preview?: VideoEntry;
}
export interface MediaResponse extends Media {
  videoPayload?: VideoPayload;
}

export interface FileMetaData {
  name: string;
  size: number;
  type: string;
  url: string | null;
  isSupported: boolean;
  index: number;
  preSignedUrl: string;
  key: string;
  uploadStatus?: PostUploadStatus;
  videoPayload?: VideoPayload;
}

export interface CreatePost {
  postId?: string;
  loading: boolean;
  medias: MediaRequest[];
  upload: {
    creatorId: string;
    selectedFiles: FileMetaData[];
    isLoading: boolean;
    exhibitedFile?: FileMetaData;
    isInitialUpload: boolean;
  };
}

export interface StreamerPosts extends PageableResponse {
  loading: boolean;
  pageable: Pageable;
  posts: PostWithStats[];
}

export interface PostsStats {
  loading: boolean;
  data?: PostsStatsData;
}

export interface PostsStatsData {
  totalLikes: number;
  totalComments: number;
  totalFollowers: number;
  overallRevenue: number;
}

export interface AdminPostsOverview extends PageableResponse {
  loading: boolean;
  queryParams: QueryParams;
  tableFilter?: TableFilter;
  dateFilter?: DateRangeWithSelectedFilter;
  audienceFilter?: Audience[];
  posts: Post[];
}

export interface PostsState {
  createPost: CreatePost;
  streamerPosts: StreamerPosts;
  postsStats: PostsStats;
  postStatus: {
    loading: boolean;
  };
  adminPostsOverview: AdminPostsOverview;
  post?: StreamerPostResponse;
  loading: boolean;
}

export const postsInitialState: PostsState = {
  createPost: {
    postId: '',
    loading: false,
    medias: [],
    upload: {
      creatorId: '',
      selectedFiles: [],
      isLoading: false,
      isInitialUpload: true,
      exhibitedFile: undefined,
    },
  },
  postStatus: {
    loading: false,
  },
  streamerPosts: {
    loading: false,
    pageable: {
      page: 0,
      pageSize: 10,
      sortField: 'createdAt',
      sortOrder: 'desc',
    },
    posts: [],
    hits: 0,
    pages: 0,
  },
  postsStats: {
    loading: false,
  },
  adminPostsOverview: {
    hits: 0,
    pages: 0,
    loading: false,
    queryParams: {
      pageSize: 10,
      pageNumber: 0,
      sortField: 'createdAt',
      sortOrder: 'desc',
      from: dayjs().format('YYYY-MM-DD'),
      to: dayjs().format('YYYY-MM-DD'),
      audience: [Audience.HELLO],
    },
    tableFilter: {
      pagination: {
        pageSize: 10,
      },
    },
    posts: [],
  },
  post: undefined,
  loading: false,
};

export const postsSlice = createSlice({
  name: 'posts',
  initialState: postsInitialState,
  reducers: {
    setCreatingPostLoading: (state: PostsState, action: PayloadAction<boolean>) => {
      state.createPost.loading = action.payload;
    },
    setPostUploadIsLoading: (state: PostsState, action: PayloadAction<boolean>) => {
      state.createPost.upload.isLoading = action.payload;
    },
    setPostId: (state: PostsState, action: PayloadAction<string>) => {
      state.createPost.postId = action.payload;
    },
    setSelectedFiles: (state: PostsState, action: PayloadAction<FileMetaData[]>) => {
      state.createPost.upload.selectedFiles = action.payload;
    },
    addSelectedFiles: (state: PostsState, action: PayloadAction<FileMetaData[]>) => {
      state.createPost.upload.selectedFiles = [
        ...state.createPost.upload.selectedFiles,
        ...action.payload,
      ];
    },
    removeUnsupportedFiles: (state: PostsState) => {
      state.createPost.upload.selectedFiles = state.createPost.upload.selectedFiles
        .filter(file => file.isSupported)
        .map((file, index) => {
          return { ...file, index };
        });
    },
    setIsInitialUpload(state: PostsState, action: PayloadAction<boolean>) {
      state.createPost.upload.isInitialUpload = action.payload;
    },
    setCreatorId(state: PostsState, action: PayloadAction<string>) {
      state.createPost.upload.creatorId = action.payload;
    },
    setStreamerPosts: (state: PostsState, action: PayloadAction<StreamerPostsResponse>) => {
      state.streamerPosts = {
        ...state.streamerPosts,
        loading: false,
        hits: action.payload.hits,
        pages: action.payload.pages,
        posts: action.payload.posts,
      };
    },
    setStreamerPostsLoading: (state: PostsState, action: PayloadAction<boolean>) => {
      state.streamerPosts.loading = action.payload;
    },
    setStreamerPostsPageable: (state: PostsState, action: PayloadAction<Pageable>) => {
      state.streamerPosts.loading = false;
      state.streamerPosts.pageable = action.payload;
    },
    setStreamerPostsStatsLoading: (state: PostsState, action: PayloadAction<boolean>) => {
      state.postsStats.loading = action.payload;
    },
    setStreamerPostsStats: (state: PostsState, action: PayloadAction<PostsStatsData>) => {
      state.postsStats = {
        loading: false,
        data: action.payload,
      };
    },
    setPostStatusLoading: (state: PostsState, action: PayloadAction<boolean>) => {
      state.postStatus.loading = action.payload;
    },
    setStreamerPostStatus: (
      state: PostsState,
      action: PayloadAction<PostStatusRequestResponse>
    ) => {
      state.streamerPosts.posts = state.streamerPosts.posts.map(post =>
        post.id === action.payload.postId ? { ...post, status: action.payload.postStatus } : post
      );
    },
    setAdminPostStatus: (state: PostsState, action: PayloadAction<PostStatusRequestResponse>) => {
      state.adminPostsOverview.posts = state.adminPostsOverview.posts.map(post =>
        post.id === action.payload.postId ? { ...post, status: action.payload.postStatus } : post
      );
    },
    setAdminPostsOverview: (state, action: PayloadAction<Partial<AdminPostsOverview>>) => {
      state.adminPostsOverview = {
        ...state.adminPostsOverview,
        ...action.payload,
      };
    },
    setPost: (state, action: PayloadAction<StreamerPostResponse>) => {
      state.post = action.payload;
      state.loading = false;
    },
    setExhibitedFile: (state, action: PayloadAction<FileMetaData | undefined>) => {
      state.createPost.upload.exhibitedFile = action.payload;
    },
    clearPostCreationData: state => {
      state.createPost.upload.selectedFiles.forEach(file => {
        // todo : make the url a string and revoke it
        if (file.url) {
          URL.revokeObjectURL(file.url);
        }
      });
      state.createPost.upload.selectedFiles = [];
      state.createPost.upload.isInitialUpload = true;
      state.createPost.upload.exhibitedFile = undefined;
    },
    setS3UploadStatus(
      state: PostsState,
      action: PayloadAction<{ preSignedUrl: string; status: PostUploadStatus }>
    ) {
      state.createPost.upload.selectedFiles = state.createPost.upload.selectedFiles.map(file =>
        file.preSignedUrl === action.payload.preSignedUrl
          ? { ...file, uploadStatus: action.payload.status }
          : file
      );
    },
    removeS3UploadStatus(state: PostsState, action: PayloadAction<string>) {
      state.createPost.upload.selectedFiles = state.createPost.upload.selectedFiles.map(file =>
        file.preSignedUrl === action.payload ? { ...file, uploadStatus: undefined } : file
      );
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchStreamerPost, state => {
      state.loading = true;
    });
  },
});

export const {
  setCreatingPostLoading,
  setStreamerPosts,
  setStreamerPostsLoading,
  setStreamerPostsPageable,
  setStreamerPostsStatsLoading,
  setStreamerPostsStats,
  setPostStatusLoading,
  setStreamerPostStatus,
  setAdminPostsOverview,
  setAdminPostStatus,
  setPost,
  setPostUploadIsLoading,
  setPostId,
  setSelectedFiles,
  setExhibitedFile,
  addSelectedFiles,
  setIsInitialUpload,
  setCreatorId,
  clearPostCreationData,
  setS3UploadStatus,
  removeS3UploadStatus,
  removeUnsupportedFiles,
} = postsSlice.actions;

export const selectCreatePost = (state: RootState) => state.posts.createPost;
export const selectStreamerPosts = (state: RootState) => state.posts.streamerPosts;
export const selectStreamerPostsPageable = (state: RootState) => state.posts.streamerPosts.pageable;
export const selectStreamerPostsStats = (state: RootState) => state.posts.postsStats;

export const selectPostStatusLoading = (state: RootState) => state.posts.postStatus.loading;
export const selectAdminPostsOverview = (state: RootState) => state.posts.adminPostsOverview;
export const selectStreamerPost = (state: RootState) => state.posts.post;
export const selectPostIsBeingLoaded = (state: RootState) => state.posts.loading;

export const selectSelectedFiles = (state: RootState) =>
  state.posts.createPost.upload.selectedFiles;
export const selectSupportedSelectedFiles = (state: RootState) =>
  state.posts.createPost.upload.selectedFiles.filter(file => file.isSupported);

export const selectPostId = (state: RootState) => state.posts.createPost.postId;

export const selectHasUploadFinished = (state: RootState) => {
  const s3loadingStatuses = state.posts.createPost.upload.selectedFiles
    .filter(file => file.isSupported)
    .map(file => file.uploadStatus)
    .filter(status => status !== undefined);
  return (
    s3loadingStatuses.length > 0 &&
    !s3loadingStatuses.some(status => status === PostUploadStatus.IN_PROGRESS)
  );
};

export const selectSelectedSupportedFiles = (state: RootState) =>
  state.posts.createPost.upload.selectedFiles
    .filter(file => file.isSupported)
    .sort((a, b) => a.index - b.index);
export const selectIsInitialUpload = (state: RootState) =>
  state.posts.createPost.upload.isInitialUpload;

export const selectExhibitedFile = (state: RootState) =>
  state.posts.createPost.upload.exhibitedFile ??
  state.posts.createPost.upload.selectedFiles.find(file => file.isSupported);

export const selectCreatorId = (state: RootState) => state.posts.createPost.upload.creatorId;

// todo : check this function
export const selectS3loadingStatuses = (
  state: RootState
): { status: PostUploadStatus | undefined; preSignedUrl: string }[] =>
  state.posts.createPost.upload.selectedFiles.map(file => {
    return { status: file.uploadStatus, preSignedUrl: file.preSignedUrl };
  });

export default postsSlice.reducer;
