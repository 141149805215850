import { Radio } from '@hse24/shared-components';
import { useState } from 'react';
import { Aspect } from '../../../utils/images/cropImageFns';
import styles from './RatioSelector.module.scss';

interface RatioSelectorProps {
  defaultValue?: Aspect;
  value?: Aspect;
  onChange?: (value: Aspect) => void;
}

const RatioSelector = ({ defaultValue, onChange }: RatioSelectorProps) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  return (
    <div className={styles.container} data-testid="ratio-selector">
      {Object.values(Aspect)
        .filter(ratio => ratio !== Aspect.RATIO_26_10) // Exclude RATIO_26_10
        .map(ratio => (
          <Radio
            key={ratio}
            selected={selectedOption === ratio}
            onSelect={() => {
              setSelectedOption(ratio);
              onChange?.(ratio);
            }}
            className={styles.ration_option}
          >
            {ratio}
          </Radio>
        ))}
    </div>
  );
};

export default RatioSelector;
