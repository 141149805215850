import Config from '../config/config';

export type ScriptDetails = {
  srcUrl: string;
  id: string | undefined;
  attributes: Record<string, string>;
};

export const userCentricsConsentScriptDetails: ScriptDetails = {
  srcUrl: 'https://app.usercentrics.eu/browser-ui/latest/loader.js',
  id: 'usercentrics-cmp',
  attributes: {
    'data-settings-id': 'uVkVWS92E',
    ...(!Config.env.isProdEnv ? { 'data-version': 'preview' } : {}),
  },
};
