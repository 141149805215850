import CustomTimer from './CustomTimer/CustomTimer';
import OptionGrid from './OptionGrid/OptionGrid';
import styles from './TimeSelector.module.scss';

export interface TimeSelectorProps {
  showDate: Date;
  updateShowDate: (date: Date) => void;
  setHasSelectedValidTime: (hasSelectedValidTime: boolean) => void;
  fetchAllSlots: boolean;
}

const TimeSelector = (props: TimeSelectorProps) => {
  return (
    <div data-testid="time-selector" className={styles.time_selector_container}>
      <OptionGrid {...props} />
      <CustomTimer
        setHasSelectedValidTime={props.setHasSelectedValidTime}
        updateShowDate={props.updateShowDate}
        showDate={props.showDate}
      />
    </div>
  );
};
export default TimeSelector;
