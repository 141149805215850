import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Header from '../../../../components/Header/Header';
import t from '../../../../constants/translation';
import AdminCreateStreamerShopLinkDialog from '../../../components/AdminCreateStreamerShopLinkDialog/AdminCreateStreamerShopLinkDialog';
import { CreatorShopLinkData } from '../../../model/creator';
import { saveCreatorShopLink } from '../../../state/creatorsSlice';
import CreatorShopLinksTable from '../CreatorShopLinksTable/CreatorShopLinksTable';
import styles from './CreatorShopLinksContainer.module.scss';

const CreatorShopLinksContainer = ({ creatorId }: { creatorId: string }) => {
  const dispatch = useDispatch();
  const [isCreationDialogOpen, setIsCreationDialogOpen] = useState(false);

  const openDialog = () => setIsCreationDialogOpen(true);

  const closeDialog = () => {
    setIsCreationDialogOpen(false);
  };

  const handleSubmit = (shopLinkData: CreatorShopLinkData) => {
    dispatch(
      saveCreatorShopLink({
        creatorId,
        shopLinkData,
      })
    );
  };

  return (
    <div className={styles.container}>
      <Header
        title={t.admins.creators.creatorDetails['Released product ranges']}
        onButtonClick={openDialog}
        buttonText={t.admins.creators.creatorDetails['Limit product range']}
      />
      <div className={styles.table}>
        <CreatorShopLinksTable creatorId={creatorId} />
      </div>

      {isCreationDialogOpen && (
        <AdminCreateStreamerShopLinkDialog
          handleSubmit={handleSubmit}
          isOpen={isCreationDialogOpen}
          handleClose={closeDialog}
        />
      )}
    </div>
  );
};
export default CreatorShopLinksContainer;
