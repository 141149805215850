import { Button, EButtonSize, EButtonType, EInputTypes } from '@hse24/shared-components';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import icons from '../../../assets';
import { isMobileBreakPoint } from '../../../common/mediaQueries';
import Icon from '../../../components/Icon/Icon';
import t from '../../../constants/translation';
import {
  cancelPostMediaUploadToS3,
  createPreSignedUrls,
  selectHasUploadFinished,
  selectIsInitialUpload,
  selectSelectedFiles,
  setIsInitialUpload,
} from '../../state/postsSlice';
import { filterSelectedFiles } from '../../utils/MediaUtils';
import UploadInstructions from '../UploadInstructions/UploadInstructions';
import { UploadStatus } from '../UploadStatus/UploadStatus';
import PostMediaRenderer from './PostMediaRenderer/PostMediaRenderer';
import styles from './UploadArea.module.scss';

interface UploadAreaProps {
  isUpdate: boolean;
}
export const UploadArea = ({ isUpdate }: UploadAreaProps) => {
  const [isUploadInstructionsPopupOpen, setIsUploadInstructionsPopupOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const isMobile = isMobileBreakPoint();

  const selectedFiles = useSelector(selectSelectedFiles);
  const hasInitialUploadFinished = useSelector(selectHasUploadFinished);
  const isInitialUpload = useSelector(selectIsInitialUpload);
  const hasSelectedFiles = selectedFiles.length > 0;

  const onCancelUploadHandler = () => {
    dispatch(cancelPostMediaUploadToS3());
  };

  const handleFileUploaded = (chosenFiles: FileList | null) => {
    if (chosenFiles) {
      const filteredFiles = filterSelectedFiles(chosenFiles);
      dispatch(
        createPreSignedUrls({
          attachments: filteredFiles.attachments,
          files: filteredFiles.files,
          isUploading: false,
        })
      );
    }
  };

  useEffect(() => {
    if (isInitialUpload) {
      dispatch(setIsInitialUpload(false));
    }
  }, [hasInitialUploadFinished]);

  useEffect(() => {
    if (selectedFiles.length === 0) {
      dispatch(setIsInitialUpload(true));
    }
  }, [selectedFiles.length]);

  return (
    <>
      {selectedFiles.length === 0 || (!hasInitialUploadFinished && isInitialUpload) ? (
        <>
          <UploadInstructions
            handleClose={setIsUploadInstructionsPopupOpen}
            isUploadDetailsPopupOpen={isUploadInstructionsPopupOpen}
          />
          <div
            onDragOver={ev => {
              ev.preventDefault();
            }}
            onDrop={(e: React.DragEvent<HTMLDivElement>) => {
              e.preventDefault();
              handleFileUploaded(e.dataTransfer.files);
            }}
            className={styles.upload_area}
          >
            <div className={styles.center_container}>
              <div data-testid="pictures-icon" className={styles.pictures_icon}>
                <Icon icon={icons.pictures} />
              </div>
              <p className={styles.description}>
                {isMobile
                  ? t.creators.post.dragAndDropFromMobile
                  : t.creators.post.dragAndDropFromComputer}
              </p>
              {hasSelectedFiles ? (
                <div className={styles.upload_statuses}>
                  {selectedFiles.map(file => {
                    return <UploadStatus key={file.name} fileMetaData={file} />;
                  })}
                  <div className={styles.cancel_upload}>
                    <Button
                      type={EButtonType.BLANK}
                      size={EButtonSize.SMALL}
                      onClick={onCancelUploadHandler}
                    >
                      {t.common.Cancel}
                    </Button>
                  </div>
                </div>
              ) : (
                <div className={styles.upload_actions}>
                  <Button
                    className={styles.upload_button}
                    onClick={() => {
                      inputRef.current?.click();
                    }}
                    size={EButtonSize.SMALL}
                    inputType={EInputTypes.button}
                  >
                    {isMobile
                      ? t.creators.post.uploadFromMobile
                      : t.creators.post.uploadFromComputer}
                  </Button>
                  <input
                    ref={inputRef}
                    className={styles.upload_input}
                    type="file"
                    onChange={event => {
                      handleFileUploaded(event.target.files);
                    }}
                    multiple={true}
                  />
                </div>
              )}
            </div>
            <div className={styles.bottom}>
              {hasSelectedFiles ? (
                <p className={styles.upload_in_progress_description}>
                  {t.creators.post.uploadInProgress}
                </p>
              ) : (
                <>
                  <p className={styles.format_message}>{t.creators.post.filesFormat}</p>
                  <p className={styles.format_message}>{t.creators.post.compatibleFormat}</p>
                  <u
                    className={styles.instructions_popup}
                    onClick={() => setIsUploadInstructionsPopupOpen(true)}
                  >
                    {t.creators.post.openInstructionsPopUp}
                  </u>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <PostMediaRenderer isUpdate={isUpdate} />
      )}
    </>
  );
};
