import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Audience } from '../../../common/types';
import AudienceFilter from '../../../components/AudienceFilter/AudienceFilter';
import DateFilterInPast from '../../../components/DateFilter/DateFilterInPast/DateFilterInPast';
import { DateRangeWithSelectedFilter } from '../../../components/DateFilter/dateFilterTypes';
import AddCreatorDialogWithButton from '../../components/AddCreatorDialogWithButton/AddCreatorDialogWithButton';
import AdminCreatorsTable from '../../components/CreatorsTable/AdminCreatorsTable';
import {
  getCreatorsOverview,
  selectCreatorsOverview,
  setCreatorsOverview,
} from '../../state/creatorsSlice';
import styles from './AdminCreatorsOverview.module.scss';

const AdminStreamersOverview = () => {
  const dispatch = useDispatch();
  const creatorsOverview = useSelector(selectCreatorsOverview);
  const dateRange = creatorsOverview.dateFilter;
  const audienceFilter = creatorsOverview.queryParams.audience;

  const onDateFiltersChange = (dateRangeWithSelectedFilter: DateRangeWithSelectedFilter) => {
    dispatch(setCreatorsOverview({ dateFilter: dateRangeWithSelectedFilter }));
  };
  const onAudienceFilterChange = (audience: Audience[]) => {
    const queryParams = { ...creatorsOverview.queryParams, audience };
    dispatch(setCreatorsOverview({ queryParams }));
    dispatch(getCreatorsOverview(queryParams));
  };

  return (
    <Box className={styles.container}>
      <Box>
        <AddCreatorDialogWithButton />
      </Box>
      <Box className={styles.table_filter}>
        <Box>
          <DateFilterInPast
            onDateFiltersChange={onDateFiltersChange}
            selectedDateFrom={dateRange?.startDate}
            selectedDateTo={dateRange?.endDate}
            selectedFilter={dateRange?.dateFilter}
          />
        </Box>
        <Box>
          <Typography variant={'h6'} mb={2}>
            Audience
          </Typography>
          <AudienceFilter onSelect={onAudienceFilterChange} audienceFilter={audienceFilter} />
        </Box>
      </Box>
      <Box>
        <AdminCreatorsTable />
      </Box>
    </Box>
  );
};

export default AdminStreamersOverview;
