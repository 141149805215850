import { LoadingSpinner } from '@hse24/shared-components';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAvailableShowTimeSlot,
  selectAvailableShowTimeSlots,
  selectShowTimeSlotsIsLoading,
} from '../../../../../../state/showsSlice';
import { TimeSelectorProps } from '../TimeSelector';
import styles from './OptionGrid.module.scss';
import TimeOption from './TimeOption/TimeOption';

const OptionGrid = (props: TimeSelectorProps) => {
  const dispatch = useDispatch();
  const availableShowTimeSlots = useSelector(selectAvailableShowTimeSlots);
  const isLoading = useSelector(selectShowTimeSlotsIsLoading);
  useEffect(() => {
    dispatch(
      fetchAvailableShowTimeSlot({ date: props.showDate, fetchAllSlots: props.fetchAllSlots })
    );
  }, []);

  return (
    <>
      {isLoading ? (
        <div data-testid="option-spinner">
          <LoadingSpinner className={styles.spinner} />
        </div>
      ) : (
        <div data-testid="option-grid" className={styles.time_options_container}>
          {availableShowTimeSlots.map(timeOption => {
            return (
              <TimeOption
                key={`${timeOption.hours}:${timeOption.minutes}`}
                timeOption={timeOption}
                updateShowDate={props.updateShowDate}
                showDate={props.showDate}
                setHasSelectedValidTime={props.setHasSelectedValidTime}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default OptionGrid;
