import { Avatar, Theme, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import Icons from '../../assets';
import { selectLoggedInCreator } from '../../creators/state/creatorsSlice';
import { getUrlWithImageManagerParams } from '../../utils/media';
import Icon from '../Icon/Icon';

interface Props {
  size?: number;
  forBottomNavbar?: boolean;
}

const ProfileImageAvatar = ({ size, forBottomNavbar }: Props) => {
  const loggedInStreamer = useSelector(selectLoggedInCreator);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Avatar
      src={
        loggedInStreamer?.profileImageUrl &&
        getUrlWithImageManagerParams(loggedInStreamer?.profileImageUrl, 170)
      }
      alt={loggedInStreamer?.name}
      sx={{
        width: size,
        height: size,
        border: forBottomNavbar ? '1.25px solid #081F3E' : undefined,
        boxSizing: 'content-box',
        margin: '3px',
        backgroundColor: isMobile ? '#FFFFFF' : '#F4F7F9',
      }}
      data-testid="profile-image-avatar"
    >
      <Icon
        icon={Icons.userProfile}
        style={{ fontSize: forBottomNavbar ? 'small' : 'large' }}
        dataTestId={'userProfile'}
      />
    </Avatar>
  );
};

export default ProfileImageAvatar;
