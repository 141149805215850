import { useDispatch, useSelector } from 'react-redux';
import { selectUserIsHSEEmployee } from '../../../auth/state/authSlice';
import Header from '../../../components/Header/Header';
import t from '../../../constants/translation';
import { openManagedDialog } from '../../../notifications/state/notificationsSlice';
import AdminAddCreatorForm from '../AddCreatorsForm/AdminAddCreatorForm/AdminAddCreatorForm';
import AgencyAddCreatorForm from '../AddCreatorsForm/AgencyAddCreatorForm/AgencyAddCreatorForm';

const AddCreatorDialogWithButton = () => {
  const dispatch = useDispatch();
  const isHseEmployee = useSelector(selectUserIsHSEEmployee);
  const dialogComponent = isHseEmployee ? AdminAddCreatorForm.name : AgencyAddCreatorForm.name;

  const openDialog = () =>
    dispatch(openManagedDialog({ title: 'Neuen Streamer erstellen', component: dialogComponent }));

  return (
    <Header
      title={t.admins.survey.Streamer}
      onButtonClick={openDialog}
      buttonText={t.admins.survey.CreateStreamer}
      showTitleOnMobile={false}
    />
  );
};

export default AddCreatorDialogWithButton;
