import { Button, EButtonText, Text } from '@hse24/shared-components';
import cx from 'classnames';
import React from 'react';
import Icons from '../../assets';
import Icon from '../Icon/Icon';
import styles from './Header.module.scss';

export interface HeaderProps {
  title: string;
  className?: string;
  titleClassName?: string;
  onButtonClick?: (() => void) | null;
  buttonText?: string | null;
  showTitleOnMobile?: boolean;
  titleTestId?: string | null;
}

const Header: React.FC<HeaderProps> = ({
  title,
  className,
  titleClassName,
  onButtonClick,
  buttonText,
  showTitleOnMobile = false,
  titleTestId = null,
}) => {
  return (
    <div className={cx(styles.header, className)}>
      <Text.J3
        className={cx(styles.title, titleClassName, {
          [styles.showOnMobile]: showTitleOnMobile,
        })}
        data-testid={titleTestId ?? undefined}
      >
        {title}
      </Text.J3>
      {onButtonClick && buttonText && (
        <div className={styles.buttonContainer}>
          <Button onClick={onButtonClick} textAlign={EButtonText.CENTER} className={styles.button}>
            <span className={styles.iconText}>
              {buttonText}
              <Icon dataTestId="plus-icon" icon={Icons.plusIcon20} width={20} height={20} />
            </span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default Header;
