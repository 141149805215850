import { LocalStorageKeys } from '../constants/localStorageKeys';
import { sharedStorageService } from '../storage/sharedStorageService';

enum Environment {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
  LOCAL = 'local',
}

export interface Flags {
  experimentalTrackingFeatureEnabled: boolean;
  isInApp: boolean;
  amasEnabled: boolean;
  enableStreamDeleting: boolean;
  isClassicNewRatioEnabled: boolean;
  goodbyeMessageForHelloStreamer: boolean;
  isAdminCreatePostActive: boolean;
  isDraftShowEnabled: boolean;
}

interface EnvConfig {
  baseRoute: string;
  streamersPortalUrl: string;
  addressCheckServiceBaseUrl: string;
  socialLifeCommerceServiceBaseUrl: string;
  connectServiceBaseUrl: string;
  customerServiceBaseUrl: string;
  basketServiceBaseUrl: string;
  assetsBaseUrl: string;
  factFinderSearchUrl: string;
  factFinderSuggestUrl: string;
  paymentServiceUrl: string;
  orderHistoryServiceUrl: string;
  baseUrl: string;
  loggingLambdaUrl: string;
  isLocalEnv: boolean;
  isProdEnv: boolean;
  pdpUrl: string;
  pdsUrl: string;
  flags: Flags;
  recaptchaKey: string;
  enableWithCredentialsForCustomerService: boolean;
  dummyCreatorId: string;
  vouchers: {
    excludedBooksSkus: string[];
  };
}

const developmentBaseUrl = 'https://development.hse.de/dpl';
const baseUrlFromWindowLocation = `https://${window.location.hostname}/dpl`;

const envConfigurations: Record<Environment, EnvConfig> = {
  [Environment.LOCAL]: {
    baseRoute: '/',
    streamersPortalUrl: 'http://localhost:3000',
    addressCheckServiceBaseUrl: '/dpl/ac',
    socialLifeCommerceServiceBaseUrl: '/dpl/slcs',
    connectServiceBaseUrl: `${developmentBaseUrl}/cns`,
    customerServiceBaseUrl: `${developmentBaseUrl}/cs`,
    basketServiceBaseUrl: `${developmentBaseUrl}/bs`,
    factFinderSearchUrl: `${developmentBaseUrl}/ffa/search/`,
    factFinderSuggestUrl: `${developmentBaseUrl}/ffa/suggest/`,
    assetsBaseUrl: '/dpl',
    orderHistoryServiceUrl: `${developmentBaseUrl}/ohs`,
    baseUrl: developmentBaseUrl,
    isLocalEnv: true,
    isProdEnv: false,
    pdpUrl: `${developmentBaseUrl}/p`,
    pdsUrl: `${developmentBaseUrl}/pds`,
    paymentServiceUrl: 'https://development.hse.de/dpl/pay',
    loggingLambdaUrl: 'https://development.hse.de/dpl/log/',
    flags: {
      experimentalTrackingFeatureEnabled:
        sharedStorageService.getItem(LocalStorageKeys.ExperimentalTrackingFeatureEnabled) ===
        'true',
      isInApp: sharedStorageService.getItem(LocalStorageKeys.InApp) === 'true',
      amasEnabled: sharedStorageService.getItem(LocalStorageKeys.AmasEnabled) === 'true',
      enableStreamDeleting: true,
      isClassicNewRatioEnabled: true,
      goodbyeMessageForHelloStreamer: true,
      isAdminCreatePostActive: true,
      isDraftShowEnabled: false,
    },
    recaptchaKey: '6Ldz47QUAAAAACoknlRDkhhHE6BbbeqdHuVNeukA',
    enableWithCredentialsForCustomerService: true,
    dummyCreatorId: 'd63575c7-fa80-4036-b37a-81205121dc38',
    vouchers: {
      excludedBooksSkus: [],
    },
  },
  [Environment.DEVELOPMENT]: {
    baseRoute: '/dpl/cpv',
    streamersPortalUrl: `${developmentBaseUrl}/cpv`,
    addressCheckServiceBaseUrl: `${developmentBaseUrl}/ac`,
    socialLifeCommerceServiceBaseUrl: `${developmentBaseUrl}/slcs`,
    connectServiceBaseUrl: `${developmentBaseUrl}/cns`,
    customerServiceBaseUrl: `${developmentBaseUrl}/cs`,
    basketServiceBaseUrl: `${developmentBaseUrl}/bs`,
    factFinderSearchUrl: `${developmentBaseUrl}/ffa/search/`,
    factFinderSuggestUrl: `${developmentBaseUrl}/ffa/suggest/`,
    assetsBaseUrl: '/dpl',
    orderHistoryServiceUrl: `${developmentBaseUrl}/ohs`,
    baseUrl: developmentBaseUrl,
    isLocalEnv: false,
    isProdEnv: false,
    pdpUrl: `${developmentBaseUrl}/p`,
    pdsUrl: `${developmentBaseUrl}/pds`,
    paymentServiceUrl: 'https://development.hse.de/dpl/pay',
    loggingLambdaUrl: 'https://development.hse.de/dpl/log/',
    flags: {
      experimentalTrackingFeatureEnabled:
        sharedStorageService.getItem(LocalStorageKeys.ExperimentalTrackingFeatureEnabled) ===
        'true',
      isInApp: sharedStorageService.getItem(LocalStorageKeys.InApp) === 'true',
      amasEnabled: sharedStorageService.getItem(LocalStorageKeys.AmasEnabled) === 'true',
      enableStreamDeleting: true,
      isClassicNewRatioEnabled: true,
      goodbyeMessageForHelloStreamer: true,
      isAdminCreatePostActive: true,
      isDraftShowEnabled: false,
    },
    recaptchaKey: '6Ldz47QUAAAAACoknlRDkhhHE6BbbeqdHuVNeukA',
    enableWithCredentialsForCustomerService: true,
    dummyCreatorId: 'd63575c7-fa80-4036-b37a-81205121dc38',
    vouchers: {
      excludedBooksSkus: [],
    },
  },
  [Environment.STAGING]: {
    baseRoute: '/dpl/cpv',
    streamersPortalUrl: `${baseUrlFromWindowLocation}/cpv`,
    addressCheckServiceBaseUrl: `${baseUrlFromWindowLocation}/ac`,
    socialLifeCommerceServiceBaseUrl: `${baseUrlFromWindowLocation}/slcs`,
    connectServiceBaseUrl: `${baseUrlFromWindowLocation}/cns`,
    customerServiceBaseUrl: `${baseUrlFromWindowLocation}/cs`,
    basketServiceBaseUrl: `${baseUrlFromWindowLocation}/bs`,
    factFinderSearchUrl: `${baseUrlFromWindowLocation}/ffa/search/`,
    factFinderSuggestUrl: `${baseUrlFromWindowLocation}/ffa/suggest/`,
    assetsBaseUrl: '/dpl',
    orderHistoryServiceUrl: `${baseUrlFromWindowLocation}/ohs`,
    baseUrl: baseUrlFromWindowLocation,
    isLocalEnv: false,
    isProdEnv: false,
    pdpUrl: `${baseUrlFromWindowLocation}/p`,
    pdsUrl: `${baseUrlFromWindowLocation}/pds`,
    paymentServiceUrl: 'https://qas.hse.de/dpl/pay',
    loggingLambdaUrl: 'https://qas.hse.de/dpl/log/',
    flags: {
      experimentalTrackingFeatureEnabled:
        sharedStorageService.getItem(LocalStorageKeys.ExperimentalTrackingFeatureEnabled) ===
        'true',
      isInApp: sharedStorageService.getItem(LocalStorageKeys.InApp) === 'true',
      amasEnabled: sharedStorageService.getItem(LocalStorageKeys.AmasEnabled) === 'true',
      enableStreamDeleting: true,
      isClassicNewRatioEnabled: true,
      goodbyeMessageForHelloStreamer: true,
      isAdminCreatePostActive: true,
      isDraftShowEnabled: false,
    },
    recaptchaKey: '6LcQp7UUAAAAAA66rHZ296pHY1M4qGjauVe35f9R',
    enableWithCredentialsForCustomerService: true,
    dummyCreatorId: '60ff93e0-e8cb-416c-a24f-1ee3b19412e4',
    vouchers: {
      excludedBooksSkus: [],
    },
  },
  [Environment.PRODUCTION]: {
    baseRoute: '/dpl/cpv',
    streamersPortalUrl: `${baseUrlFromWindowLocation}/cpv`,
    addressCheckServiceBaseUrl: `${baseUrlFromWindowLocation}/ac`,
    socialLifeCommerceServiceBaseUrl: `${baseUrlFromWindowLocation}/slcs`,
    connectServiceBaseUrl: `${baseUrlFromWindowLocation}/cns`,
    customerServiceBaseUrl: `${baseUrlFromWindowLocation}/cs`,
    basketServiceBaseUrl: `${baseUrlFromWindowLocation}/bs`,
    factFinderSearchUrl: `${baseUrlFromWindowLocation}/ffa/search/`,
    factFinderSuggestUrl: `${baseUrlFromWindowLocation}/ffa/suggest/`,
    assetsBaseUrl: '/dpl',
    orderHistoryServiceUrl: `${baseUrlFromWindowLocation}/ohs`,
    baseUrl: baseUrlFromWindowLocation,
    isLocalEnv: false,
    isProdEnv: true,
    pdpUrl: `${baseUrlFromWindowLocation}/p`,
    pdsUrl: `${baseUrlFromWindowLocation}/pds`,
    paymentServiceUrl: 'https://www.hse.de/dpl/pay',
    loggingLambdaUrl: 'https://www.hse.de/dpl/log/',
    flags: {
      experimentalTrackingFeatureEnabled: false,
      isInApp: sharedStorageService.getItem(LocalStorageKeys.InApp) === 'true',
      amasEnabled: sharedStorageService.getItem(LocalStorageKeys.AmasEnabled) === 'true',
      enableStreamDeleting: true,
      isClassicNewRatioEnabled: true,
      goodbyeMessageForHelloStreamer: true,
      isAdminCreatePostActive: false,
      isDraftShowEnabled: false,
    },
    recaptchaKey: '6Lc0prUUAAAAABAXZkXVHUSvGVvoee4aLZ4VdQRk',
    enableWithCredentialsForCustomerService: true,
    dummyCreatorId: 'c949f35b-9dba-4b92-a65b-96a33fb5e663',
    vouchers: {
      excludedBooksSkus: [
        '457942',
        '453208',
        '453207',
        '452672',
        '390388',
        '396635',
        '379121',
        '450108',
        '406220',
        '379741',
        '445519',
        '458040',
        '455058',
      ],
    },
  },
};

const environmentConfiguration = (environment: string): EnvConfig => {
  switch (environment) {
    case 'production':
      return envConfigurations.production;
    case 'staging':
      return envConfigurations.staging;
    case 'development':
      return envConfigurations.development;
    default:
      return envConfigurations.local;
  }
};

const Config = {
  env: environmentConfiguration(process.env.REACT_APP_ENVIRONMENT || Environment.LOCAL),
};

export default Config;
