import { useSelector } from 'react-redux';
import { selectUserIsHSEEmployee } from '../../auth/state/authSlice';
import { isMobileBreakPoint } from '../../common/mediaQueries';
import { DesktopNavbar } from './DesktopNavbar/DesktopNavbar';
import { MobileNavbar } from './MobileNavbar/MobileNavbar';

const Navbar = () => {
  const isMobile = isMobileBreakPoint();
  const isHseEmployee = useSelector(selectUserIsHSEEmployee);

  if (!isMobile) {
    return <DesktopNavbar />;
  }

  if (isMobile && isHseEmployee) {
    return <MobileNavbar />;
  }

  return null;
};

export default Navbar;
