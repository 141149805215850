import React, { ReactNode, useRef } from 'react';
import { useHistory } from 'react-router-dom';
interface SwipeToNavigateBackProps {
  children: ReactNode;
  threshold?: number;
}
const SwipeToNavigateBack: React.FC<SwipeToNavigateBackProps> = ({ children, threshold = 150 }) => {
  const history = useHistory();
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    touchEndX.current = e.changedTouches[0].clientX;
    handleSwipe();
  };

  const handleSwipe = () => {
    const swipeDistance = touchEndX.current - touchStartX.current;
    if (swipeDistance > threshold) {
      history.goBack();
    }
  };

  return (
    <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
      {children}
    </div>
  );
};
export default SwipeToNavigateBack;
