import {
  Button,
  EButtonSize,
  EButtonType,
  EInputTypes,
  Error,
  ETag,
  Text,
} from '@hse24/shared-components';
import { Box, Grid, Typography } from '@mui/material';
import { format, subMinutes } from 'date-fns';
import dayjs from 'dayjs';
import { ErrorMessage, Form, Formik } from 'formik';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Icons from '../../../assets';
import FormikTextField from '../../../components/FormikTextField/FormikTextField';
import Header from '../../../components/Header/Header';
import RouteLeavingGuard from '../../../components/RouteLeavingGuard/RouteLeavingGuard';
import TextField from '../../../components/TextField/TextField';
import { LocalStorageKeys } from '../../../constants/localStorageKeys';
import t from '../../../constants/translation';
import { fetchLoggedInCreator } from '../../../creators/state/creatorsSlice';
import { selectProductSelection } from '../../../products/state/productsSlice';
import routePaths from '../../../routes/routePaths';
import { useShowImages } from '../../../shared/useShowImages';
import { sharedStorageService } from '../../../storage/sharedStorageService';
import { toBerlinTimeZone } from '../../../utils/dates';
import { ShowResponse } from '../../api/showsRequestResponse';
import { ImageType, ScheduleShowData, ShowCoverSelectionMetadata } from '../../model/shows';
import { mapTags } from '../../utils/mapTags';
import { mapCurtainsToShowImages } from '../../utils/showImages';
import useFormChanged from '../../utils/useFormChanged';
import ShowCoverSelection from '../ShowCoverSelection/ShowCoverSelection';
import ShowProductsSelection from '../ShowProductsSelection/ShowProductsSelection';
import styles from './ScheduleShowForm.module.scss';
import { creatorShowSchema } from './ShowSchema/ShowSchema';
import DateSelector from './ShowStartTimePicker/DateSelector/DateSelector';
import ShowStartTimePicker from './ShowStartTimePicker/ShowStartTimePicker';

export interface ShowData {
  title?: string | null;
  preview?: string;
  baseProductsNo: string[];
  tags?: string[];
  scheduledStartAt?: string;
  voucherId?: string;
}

export interface ShowFormProps {
  isCreate: boolean;
  isOwnShow?: boolean;
  showToUpdate?: ShowResponse;
  submitCallback: (values: ScheduleShowData) => void;
}

const showCoverSelectionMetadata: ShowCoverSelectionMetadata = {
  displayTitles: false,
  icon: Icons.videoCamera,
  title: 'Vorschaubild',
  subTitle:
    'Du kannst ein Bild hochladen, das den Followern zeigt, was in ' +
    'deinem Stream passiert. Ein gutes Vorschaubild fällt auf und erzeugt Interesse bei den Zuschauern.',
};

const ScheduleShowForm: FC<ShowFormProps> = ({
  isCreate,
  submitCallback,
  isOwnShow,
  showToUpdate,
}: ShowFormProps) => {
  const nowSub5min = useMemo(() => subMinutes(new Date(), 5), []);
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedProducts } = useSelector(selectProductSelection);
  const [showImages] = useShowImages(mapCurtainsToShowImages(showToUpdate?.curtains));

  const [formValues, setFormValues] = useState({
    title: showToUpdate?.title || '',
    creatorId: showToUpdate?.creator?.id || '',
    preview: mapCurtainsToShowImages(showToUpdate?.curtains)?.previewImageUrl || '',
    scheduledAt: showToUpdate?.scheduledAt ? dayjs(showToUpdate.scheduledAt).toDate() : undefined,
    voucherId: '',
  });

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [isDateSelectorOpen, setIsDateSelectorOpen] = useState(false);
  const [shouldDisplayHeaderField, setShouldDisplayHeaderField] = useState(false);

  const getScheduleAt = () => formValues.scheduledAt?.toISOString() || '';

  const hasFormChanged = useFormChanged<ShowData>(
    {
      title: formValues.title,
      preview: formValues.preview,
      baseProductsNo: selectedProducts.map(p => p.baseProductNo),
      scheduledStartAt: getScheduleAt(),
      voucherId: formValues.voucherId,
    },
    showToUpdate
      ? {
          title: showToUpdate.title,
          preview: mapCurtainsToShowImages(showToUpdate?.curtains)?.previewImageUrl,
          baseProductsNo: showToUpdate.products?.map(p => p.baseProductNo) || [],
          scheduledStartAt: dayjs(showToUpdate.scheduledAt).toDate().toISOString(),
          voucherId: '',
        }
      : null
  );

  const initialValues: ScheduleShowData & { productSearch: string } = {
    header: showToUpdate?.header ?? '',
    title: showToUpdate?.title ?? '',
    tags: showToUpdate?.tags ? mapTags(showToUpdate.tags) : [],
    scheduledStartAt: showToUpdate?.scheduledAt ?? '',
    baseProductsNo: [], // Will be taken from the selector in the child component
    preview: showImages.previewImageUrl
      ? {
          imageType: ImageType.PREVIEW,
          fileExtension: 'png',
          imageUrl: showImages.previewImageUrl,
          changed: false,
        }
      : undefined,
    productSearch: '',
  };

  useEffect(() => {
    dispatch(fetchLoggedInCreator());
    const displayHeaderFieldItem = sharedStorageService.getItem(
      LocalStorageKeys.displayHeaderField
    );
    setShouldDisplayHeaderField(displayHeaderFieldItem === 'true');
  }, []);

  const mapFormValuesToScheduleShowData = (
    values: ScheduleShowData & { productSearch: string }
  ): ScheduleShowData => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { productSearch, ...rest } = values;
    rest.scheduledStartAt = formValues.scheduledAt
      ? toBerlinTimeZone(formValues.scheduledAt).toISOString()
      : '';
    rest.baseProductsNo = selectedProducts.map(
      selectedProduct => selectedProduct.variantId ?? selectedProduct.baseProductNo
    );
    return rest;
  };

  const handleCancel = () => history.push(routePaths.creator.dashboard);

  const setFormValue = (field: string, value: string | Date) => {
    setFormValues({
      ...formValues,
      [field]: value,
    });
  };

  const renderErrorMsg = (message: string) => (
    <Error className={styles.error_message} error={message} />
  );

  const displayButton = isOwnShow || isCreate;

  return (
    <>
      <Header title={t.creators['Schedule a stream']} showTitleOnMobile={true} />
      <Formik
        initialValues={initialValues}
        validationSchema={creatorShowSchema}
        onSubmit={values => {
          setIsFormSubmitted(true);
          submitCallback(mapFormValuesToScheduleShowData(values));
        }}
        initialTouched={{
          scheduledStartAt: false,
          title: false,
          baseProductsNo: false,
        }}
        initialErrors={{ baseProductsNo: '' }}
      >
        {({ setFieldTouched, setFieldValue, errors, touched }) => {
          return (
            <Form>
              <div className={styles.wrapper} data-testid="classic-show-form">
                <Grid className={styles.container} container spacing={2}>
                  <Grid container item xs={12} md={7} spacing={4} className={styles.inputs}>
                    <Grid item xs={12}>
                      <Text.Body tag={ETag.DIV} className={styles.label}>
                        {t.creators.show['Scheduled stream start']}
                      </Text.Body>
                      <ShowStartTimePicker
                        scheduledStartAt={getScheduleAt()}
                        onClick={() => setIsDateSelectorOpen(true)}
                        hasError={
                          errors.scheduledStartAt != undefined && touched.scheduledStartAt == true
                        }
                        isButton
                      />
                      <div className={styles.date_Selector}>
                        <FormikTextField
                          data-testid="scheduledStartAt"
                          fieldName="scheduledStartAt"
                          value={format(formValues.scheduledAt ?? nowSub5min, 'dd.MM.yyyy HH:mm')}
                          label="Startzeit"
                        />
                      </div>
                      <Box mt={2}>
                        <ErrorMessage name="scheduledStartAt">
                          {() => renderErrorMsg(t.validation['Please set a time'])}
                        </ErrorMessage>
                      </Box>
                    </Grid>
                    {isDateSelectorOpen && (
                      <DateSelector
                        title={t.creators.show.DateSelectorTitle}
                        isDialogOpen={isDateSelectorOpen}
                        closeDialog={() => {
                          setIsDateSelectorOpen(false);
                        }}
                        plannedAt={formValues.scheduledAt ?? nowSub5min}
                        updatePlannedAt={(date: Date) => {
                          setFormValue('scheduledAt', date);
                          setFieldTouched('scheduledStartAt', true);
                          setFieldValue('scheduledStartAt', format(date, 'dd.MM.yyyy HH:mm'));
                        }}
                        fetchAllSlots={false}
                      />
                    )}
                    <Grid item xs={12}>
                      <Text.Body tag={ETag.DIV} className={styles.label}>
                        {t.creators.show['Stream title']}
                      </Text.Body>
                      {shouldDisplayHeaderField && (
                        <TextField fieldName="header" value={showToUpdate?.header} label="Header" />
                      )}
                      <TextField
                        fieldName="title"
                        value={showToUpdate?.title}
                        onChange={value => setFormValue('title', value ?? '')}
                        label="Maximal 60 Zeichen"
                        maxChars={60}
                      />
                      {errors.title !== undefined &&
                        isSubmitClicked &&
                        renderErrorMsg(t.validation['Please enter a title'])}
                    </Grid>
                    <Grid item xs={12}>
                      <Text.Body tag={ETag.DIV} className={styles.label}>
                        {t.creators.show['Products']}
                      </Text.Body>
                      <ShowProductsSelection
                        hasError={
                          errors.baseProductsNo !== undefined &&
                          isSubmitClicked &&
                          (selectedProducts == undefined || selectedProducts.length == 0)
                        }
                        isButton
                      />
                      {errors.baseProductsNo !== undefined &&
                        isSubmitClicked &&
                        (selectedProducts == undefined || selectedProducts.length == 0) && (
                          <ErrorMessage name="baseProductsNo">
                            {() =>
                              renderErrorMsg(t.validation['Please include one or more products'])
                            }
                          </ErrorMessage>
                        )}
                    </Grid>
                    <Grid container item xs={12} className={styles.desktop_actions}>
                      <Button
                        onClick={handleCancel}
                        type={EButtonType.BLANK}
                        inputType={EInputTypes.button}
                      >
                        <Typography fontWeight={700}> {t.common.Cancel}</Typography>
                      </Button>
                      {displayButton && (
                        <Button
                          type={EButtonType.PRIMARY}
                          inputType={EInputTypes.submit}
                          onClick={() => {
                            setIsSubmitClicked(true);
                          }}
                          data-testid="submit-button"
                        >
                          {isCreate ? (
                            <Typography fontWeight={700}>Stream erstellen</Typography>
                          ) : (
                            <Typography fontWeight={700}> Stream ändern</Typography>
                          )}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Grid item xs={12}>
                      <ShowCoverSelection
                        hasError={
                          (errors.preview !== undefined || formValues.preview == '') &&
                          showImages.previewImageUrl == undefined &&
                          isSubmitClicked
                        }
                        fieldName="preview"
                        imageType={ImageType.PREVIEW}
                        defaultImage={showImages.previewImageUrl}
                        metadata={showCoverSelectionMetadata}
                        onChange={imageUrl => setFormValue('preview', imageUrl ?? '')}
                      />

                      {(errors.preview !== undefined || formValues.preview == '') &&
                        isSubmitClicked &&
                        showImages.previewImageUrl == undefined &&
                        renderErrorMsg(t.validation['Please insert a preview image'])}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container gap={2} className={styles.actions}>
                  <Button
                    type={EButtonType.BLANK}
                    onClick={handleCancel}
                    inputType={EInputTypes.button}
                  >
                    <Typography fontWeight={700}> {t.common.Cancel}</Typography>
                  </Button>
                  {displayButton && (
                    <Button
                      type={EButtonType.PRIMARY}
                      inputType={EInputTypes.submit}
                      size={EButtonSize.MEDIUM}
                      onClick={() => {
                        setIsSubmitClicked(true);
                      }}
                      data-testid="submit-button"
                    >
                      {isCreate ? (
                        <Typography fontWeight={700}>Stream erstellen</Typography>
                      ) : (
                        <Typography fontWeight={700}> Stream ändern</Typography>
                      )}
                    </Button>
                  )}
                </Grid>
              </div>
            </Form>
          );
        }}
      </Formik>
      <RouteLeavingGuard blockNavigation={hasFormChanged} navigationPermitted={isFormSubmitted} />
    </>
  );
};

export default ScheduleShowForm;
