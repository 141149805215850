import React from 'react';
import GobackHeader from '../../../components/GoBackHeader/GobackHeader';
import SwipeToNavigateBack from '../../../components/SwipeToNavigateBack/SwipeToNavigateBack';
import StreamingSettings from '../../../shows/component/StreamingSettings/StreamingSettings';
import CreatorAccountPage from '../CreatorAccountPage/CreatorAccountPage';
import styles from './CreatorStreamSettings.module.scss';
const CreatorStreamSettings = () => {
  return (
    <SwipeToNavigateBack>
      <div className={styles.container}>
        <GobackHeader className={styles.back_button} />
        <CreatorAccountPage />
        <StreamingSettings />
      </div>
    </SwipeToNavigateBack>
  );
};

export default CreatorStreamSettings;
