import { Container, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import StreamerOnboardingDialog from '../../notifications/components/StreamerOnboardingDialog/StreamerOnboardingDialog';

const useStyles = (
  fullHeight: boolean,
  spacing?: number,
  backgroundColor?: string,
  overflowX?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto'
) => {
  const padding = (theme: Theme) => (spacing ? { padding: theme.spacing(spacing) } : {});
  const madeStyles = makeStyles((theme: Theme) =>
    createStyles({
      content: {
        height: fullHeight ? '100%' : 'auto',
        minHeight: '100%',
        ...(overflowX && {
          overflowX,
        }),
        [theme.breakpoints.down('md')]: {
          paddingBottom: 'calc( env(safe-area-inset-bottom) + 68px)',
          paddingRight: '24px',
          paddingLeft: '24px',
          // this is needed to give some space between the main content and BottomNavbar
          // safe-area-inset-bottom + 56px bottom navbar + 12px padding
        },
        [theme.breakpoints.up('xs')]: {
          maxWidth: 'xs',
        },
        [theme.breakpoints.up('sm')]: {
          maxWidth: 'sm',
        },
        [theme.breakpoints.up('md')]: {
          maxWidth: 'md',
        },
        [theme.breakpoints.up('lg')]: {
          maxWidth: 'lg',
        },
        [theme.breakpoints.up('xl')]: {
          maxWidth: 'xl',
        },
        ...padding(theme),
        backgroundColor: backgroundColor,
      },
    })
  );
  return madeStyles();
};

interface Props {
  pageToRender: JSX.Element;
  spacing?: number;
  backgroundColor?: string;
  overflowX?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto';
  fullHeight?: boolean;
}

const PageContainer = ({
  pageToRender,
  spacing = 2,
  backgroundColor,
  overflowX,
  fullHeight = false,
}: Props) => {
  const classes = useStyles(fullHeight, spacing, backgroundColor, overflowX);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  }, []);

  return (
    <div className={classes.content}>
      <Container style={{ maxWidth: 1600 }}>
        <StreamerOnboardingDialog />
        {pageToRender}
      </Container>
    </div>
  );
};

export default PageContainer;
