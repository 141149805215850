import PageContainer from '../../../layout/PageContainer/PageContainer';
import theme from '../../../theme/theme.module.scss';
import ProfileDashboard from '../../components/Profile/ProfileDashboard/ProfileDashboard';

const ProfileDashboardPage = () => {
  const dashboard = () => (
    <>
      <ProfileDashboard />
    </>
  );

  return <PageContainer pageToRender={dashboard()} backgroundColor={theme.whiteLilac} fullHeight />;
};

export default ProfileDashboardPage;
